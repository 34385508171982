





































import { Component, Model } from "vue-property-decorator";
import { CreateSolutionProductModule } from "../store";
import { EditSolutionProductCommand } from "@/services/iOrderService";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

@Component( {} )
export default class PgaSolutionProductEdit extends mixins( SharedMixin )
{
	@Model( "", { type: Object } ) readonly Model!: EditSolutionProductCommand;

	private typologyItems =  
	[
		{
			Name: 'Solution',
			Value: 'Solution',
		},
		{
			Name: 'Product',
			Value: 'Product',
		}
	];

	get code (): string
	{
		return this.Model?.Code ?? "";
	}
	set code ( code: string )
	{
		CreateSolutionProductModule.SET_SOLUTIONPRODUCT_EDIT_CODE( code );
	}

	get description (): string
	{
		return this.Model?.Description ?? "";
	}
	set description ( description: string )
	{
		CreateSolutionProductModule.SET_SOLUTIONPRODUCT_EDIT_DESCRIPTION( description );
	}

	get typology (): string
	{
		return this.Model.Typology;
	}
	set typology ( typology: string )
	{
		CreateSolutionProductModule.SET_SOLUTIONPRODUCT_EDIT_TIPOLOGY( typology );
	}

	get Errors (): Map<string, Array<string>>
	{
		return CreateSolutionProductModule.SolutionProductEdit.Errors;
	}
}
