














import { Component } from "vue-property-decorator";
import { CreateSolutionProductModule } from '../store';
import { SolutionProductModel } from "@/services/iOrderService";

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";

@Component({})
export default class PgaTechAreaIndex extends mixins(SharedMixin) {
    isLoading = true;

    headers: Array<any> = [
        {
            text: 'Code',
            align: 'start',
            sortable: true,
            value: 'Code',
            width: '10%',
            keySearch: "co",
        },
        {
            text: 'Tipology',
            align: 'start',
            sortable: true,
            value: 'Typology',
            keySearch: "ty",
        },
        {
            text: 'Description',
            align: 'start',
            sortable: true,
            value: 'Description',
            keySearch: "de",
        },
        {
            text: "Status",
            align: "start",
            value: "Status",
            width: "10%",
        },
    ];

    get solutionProduct(): Array<SolutionProductModel> {
        return CreateSolutionProductModule.SolutionProducts;
    }

    async mounted() {
        await CreateSolutionProductModule.getAllSolutionProduct();
        this.isLoading = false;
    }

    rowClicked(item: any) {
        const id = item.Id;
        this.$router.push({ name: 'solutionproductsdetails', params: { id } });
    }
}
