









































































import { Component } from "vue-property-decorator";
import { Guid } from "guid-typescript";

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import { CreateSolutionProductModule } from './store';
import PgaSolutionProductsDetails from './Components/PgaSolutionProductsDetails.vue';
import { EntityOrderStatus, SolutionProductModel } from '@/services/iOrderService';
import { SendEmailToPermissionsCommand } from "@/services/iNotificationService";
import { EmailsStore } from "@/feature/Shared/Email/store";

@Component( {
	components: {
		PgaSolutionProductsDetails
	}
} )
export default class SolutionProductsPageDetails extends mixins( SharedMixin )
{
	private showDialog = false;
	private title = "";
	private message = "";

	get solutionProductDetails (): SolutionProductModel
	{
		return CreateSolutionProductModule.SolutionProductDetails;
	}

	get isRequestableDeletion (): boolean
	{
		return this.solutionProductDetails.Status !== EntityOrderStatus.DeletionRequest &&
			this.solutionProductDetails.Status !== EntityOrderStatus.Created;
	}

	get isApprovable (): boolean 
	{
		return this.solutionProductDetails?.Status == EntityOrderStatus.Created;
	}

	get isApprovableDeletion (): boolean 
	{
		return this.solutionProductDetails?.Status == EntityOrderStatus.DeletionRequest;
	}

	get isEditable() : boolean
	{
		return this.solutionProductDetails.Status == EntityOrderStatus.Created;
	}

	ShowDialogDelete (): void
	{
		this.title = "Deleting Solution Product";
		this.message = `Are you sure you want to delete the Solution Product '${this.solutionProductDetails.Description}'?`;
		this.showDialog = true;
	}

	ShowDialogRequestDelete (): void
	{
		this.title = "Request Deletion Solution Product";
		this.message = `Are you sure you want to requeste deletion of Solution Product '${this.solutionProductDetails.Description}'?`;
		this.showDialog = true;
	}

	HideDialog (): void
	{
		this.showDialog = false;
		this.title = "";
		this.message = "";
	}

	async approve (): Promise<void> 
	{
		try
		{
			await CreateSolutionProductModule.ApproveSolutionProduct();
		} catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE(
				`error approving SolutionProduct: ${reason}`
			);
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}

	async approveDeletion (): Promise<void> 
	{
		try
		{
			await CreateSolutionProductModule.ApproveDeletionSolutionProduct();
			this.$router.go( -1 );
		} catch ( reason )
		{
			SharedModule.SET_ALERT_ERROR_MESSAGE( `error approving deletion SolutionProduct: ${reason}` );
			SharedModule.SET_ALERT_IS_VISIBLE( true );
		}
	}

	async deleteSolutionProduct (): Promise<void>
	{
		await CreateSolutionProductModule.DeleteSolutionProductAPI( this.solutionProductDetails.Id );

		this.showDialog = false;
		this.$router.go( -1 );
	}

	private Edit() : void
	{
		const id: string = this.solutionProductDetails.Id.toString();
		this.$router.push({ name: "solutionproductsedit", params: { id } });
	}

	private requestChanges()
	{
		const sendEmailCommand : SendEmailToPermissionsCommand = 
		{
			PermissionIds: ["2A4F361C-5633-46B3-9C04-01C6677D0007"],
			Subject: `Request changes for SolutionProduct with Code ${this.solutionProductDetails.Code}`,
			Body: "",
		};
		EmailsStore.SET_SEND_EMAIL_TO_PERMISSIONS_COMMAND(sendEmailCommand);
		EmailsStore.SET_SHOW_FORM_EMAIL_TO_PERMISSIONS(true);
	}

	async mounted (): Promise<void>
	{
		const SolutionProductId: Guid = Guid.parse( this.$route.params.id );
		CreateSolutionProductModule.getSolutionProductById( SolutionProductId );
	}
}
