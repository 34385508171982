import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import { Guid } from 'guid-typescript';
import store from '@/infrastructure/store'

import pgaDiContainer from '@/App.container';
import OrderService from '@/services/OrderService';
import IOrderService from '@/services/iOrderService';

import { SolutionProductModel,
        CreateSolutionProductCommand, EditSolutionProductCommand,
        EditSolutionProductResponse } from '@/services/iOrderService';

import { SharedModule } from '@/feature/Shared/store';
import { EntityOrderStatus, CreateSolutionProductResponse } from '@/services/iOrderService';

export interface CreateSolutionProductState {
    SolutionProductCreate: CreateSolutionProductCommand;
    SolutionProducts: Array<SolutionProductModel>;
    SolutionProductDetails: SolutionProductModel;
    SolutionProductEdit: EditSolutionProductModel;
}

export interface EditSolutionProductModel
{
    Command: EditSolutionProductCommand | null;
    Response: EditSolutionProductResponse | null;
    Errors: Map<string, Array<string>>;
}

@Module({ namespaced: true, dynamic: true, store, name: 'CreateSolutionProductModule', })
class CreateSolutionProductStore extends VuexModule implements CreateSolutionProductState {

    private _orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    public SolutionProductDetails: SolutionProductModel = {
        Id: Guid.createEmpty(),
        Code: '',
        Description: '',
        Typology: '',
        TenantId: '',
        Status: EntityOrderStatus.Created,
    };

    public SolutionProductCreate: CreateSolutionProductCommand = {
        Code: '',
        Description: '',
        Typology: '',
        Errors: new Map<string, Array<string>>()
    };

    public SolutionProductEdit: EditSolutionProductModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>(),
    }

    public SolutionProducts: Array<SolutionProductModel> = [];

    @Action({ rawError: true })
    public async getAllSolutionProduct(): Promise<Array<SolutionProductModel>> {
        const { SolutionProducts } = await this._orderService.GetAllSolutionProducts();
        this.setSolutionProduct(SolutionProducts);
        return SolutionProducts;
    }
    @Mutation
    public setSolutionProduct(solutionProduct: Array<SolutionProductModel>) {
        this.SolutionProducts = solutionProduct
    }
    @Action({ rawError: true })
    public async createSolutionProduct(): Promise<CreateSolutionProductResponse> {

        try {
            SharedModule.SET_ISLOADING(true);
            const response = await this._orderService.CreateSolutionProduct(this.SolutionProductCreate);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_RESPONSE_CREATE(errs);

            throw error
        }
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_CODE_CREATE(Code: string) {
        this.SolutionProductCreate.Code = Code;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_CREATE_DESCRIPTION(Description: string) {
        this.SolutionProductCreate.Description = Description;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_CREATE_TYPOLOGY(Typology: string) {
        this.SolutionProductCreate.Typology = Typology;
    }
    @Mutation
    public SET_RESPONSE_CREATE(errors: Map<string, Array<string>>) {
        this.SolutionProductCreate.Errors = errors;
    }
    @Action({ rawError: true })
    public async getSolutionProductById(Id: Guid): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        const SolutionProduct = await this._orderService.GetSolutionProductById(Id);

        this.SET_SOLUTIONPRODUCT_ID(SolutionProduct.Id);
        this.SET_SOLUTIONPRODUCT_CODE(SolutionProduct.Code);
        this.SET_SOLUTIONPRODUCT_NAME(SolutionProduct.Description);
        this.SET_SOLUTIONPRODUCT_TYPOLOGY(SolutionProduct.Typology);
        this.SET_SOLUTIONPRODUCT_STATUS(SolutionProduct.Status);
        this.SET_SOLUTIONPRODUCT_TENANT(SolutionProduct.TenantId);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_ID(Id: Guid) {
        this.SolutionProductDetails.Id = Id;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_CODE(Code: string) {
        this.SolutionProductDetails.Code = Code;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_NAME(Description: string) {
        this.SolutionProductDetails.Description = Description;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_TYPOLOGY(Typology: string) {
        this.SolutionProductDetails.Typology = Typology;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_STATUS(status: EntityOrderStatus) {
        this.SolutionProductDetails.Status = status;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_TENANT(TenantId: string) {
        this.SolutionProductDetails.TenantId = TenantId;
    }
    @Action({ rawError: true })
    public async DeleteSolutionProductAPI(Id: Guid): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        await this._orderService.DeleteSolutionProduct(Id);
        this.DROP_SOLUTIONPRODUCT(Id);
        SharedModule.SET_ISLOADING(false);
    }
    @Mutation
    public DROP_SOLUTIONPRODUCT(Id: Guid) {
        this.SolutionProducts = this.SolutionProducts.filter(item => item.Id !== Id);
    }

    @Action({ rawError: true })
    public RESET_STATE_CREATE()
    {
        this.RESET_TIPOLOGIA_FORNITURA();
    }
    @Mutation
    public RESET_TIPOLOGIA_FORNITURA()
    {
        this.SolutionProductCreate.Code = "";
        this.SolutionProductCreate.Errors = new Map<string, Array<string>>();
        this.SolutionProductCreate.Description = "";
        this.SolutionProductCreate.Typology = "";
    }

    // EDIT SOLUTIONPRODUCT
    @Action({ rawError: true })
    public async EditSolutionProduct() : Promise<EditSolutionProductResponse>
    {
        try {
            let response = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            if (this.SolutionProductEdit.Command)
                response = await this._orderService.EditSolutionProduct(this.SolutionProductEdit.Command.Id, this.SolutionProductEdit.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_SOLUTIONPRODUCT_EDIT_ERRORS(errs);
            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetSolutionProductToEdit() {
        SharedModule.SET_ISLOADING(true);
        const sp: SolutionProductModel = await this._orderService.GetSolutionProductById(this.SolutionProductDetails.Id);

        const editSolutionProductCommand: EditSolutionProductCommand = 
        {
            Id: sp.Id,
            Code: sp.Code,
            Description: sp.Description,
            Typology: sp.Typology,
        }

        this.SET_SOLUTIONPRODUCT_EDIT(editSolutionProductCommand);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropSolutionProductEdit()
    {
        this.SET_SOLUTIONPRODUCT_EDIT(null);
        this.SET_SOLUTIONPRODUCT_EDIT_ERRORS(new Map<string, Array<string>>());
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT(economicLimit: EditSolutionProductCommand | null)
    {
        this.SolutionProductEdit.Command = economicLimit;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT_ID(id: Guid)
    {
        if (this.SolutionProductEdit.Command)
            this.SolutionProductEdit.Command.Id = id;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT_CODE(code: string) 
    {
        if (this.SolutionProductEdit.Command)
            this.SolutionProductEdit.Command.Code = code;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT_DESCRIPTION(description: string)
    {
        if (this.SolutionProductEdit.Command)
            this.SolutionProductEdit.Command.Description = description;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT_TIPOLOGY(tipology: string)
    {
        if (this.SolutionProductEdit.Command)
            this.SolutionProductEdit.Command.Typology = tipology;
    }
    @Mutation
    public SET_SOLUTIONPRODUCT_EDIT_ERRORS(errors: Map<string, Array<string>>)
    {
        this.SolutionProductEdit.Errors = errors;
    }

    // APPROVE
    @Action({ rawError: true })
    public async ApproveSolutionProduct(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.SolutionProductDetails == null)
                return Promise.reject("Details SolutionProduct not initialized, try reload the application");

            const sp = this.SolutionProductDetails;
            const cmd = { Id: sp.Id };
            await this._orderService.ApproveSolutionProduct(cmd);

            this.SET_SOLUTIONPRODUCT_STATUS(EntityOrderStatus.Approved);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }

    // APPROVE DELETION
    @Action({ rawError: true })
    public async ApproveDeletionSolutionProduct(): Promise<void> {
        SharedModule.SET_ISLOADING(true);
        try {
            if (this.SolutionProductDetails == null)
                return Promise.reject("Details SolutionProduct not initialized, try reload the application");

            const sp = this.SolutionProductDetails;
            const cmd = { Id: sp.Id };
            await this._orderService.ApproveDeletionSolutionProduct(cmd);

            this.SET_SOLUTIONPRODUCT_STATUS(EntityOrderStatus.Deleted);
        } finally {
            SharedModule.SET_ISLOADING(false);
        }
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();

    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }

    return errs;
}

export const CreateSolutionProductModule = getModule(CreateSolutionProductStore);