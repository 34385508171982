


















































	import { Component, Model } from "vue-property-decorator";
	import { EntityOrderStatus, SolutionProductModel } from "@/services/iOrderService";

	import { mixins } from "vue-class-component";
	import SharedMixin from "@/mixins/SharedMixin";

	@Component({})
    export default class SolutionProductDetails extends mixins( SharedMixin )
    {
		@Model( "", { type: Object } ) readonly Model!: SolutionProductModel;

		get hasSolutionProduct (): boolean
		{
			return this.Model !== undefined;
        }
        
        get status() : EntityOrderStatus
        {
            return this.Model.Status;
        }
	}
