

















	import { Component } from "vue-property-decorator";
	import { CreateSolutionProductModule } from './store';
	import { mixins } from 'vue-class-component';
	import SharedMixin from "@/mixins/SharedMixin";

	import PgaSolutionProductsCreate from './Components/PgaSolutionProductsCreate.vue';
import { CreateSolutionProductCommand } from "@/services/iOrderService";
	@Component( {
		components: {
			PgaSolutionProductsCreate
		}
	} )
    export default class SolutionProductsPageCreate extends mixins( SharedMixin )
    {

		get solutionProductCreate () : CreateSolutionProductCommand
		{
			return CreateSolutionProductModule.SolutionProductCreate;
		}

		private async CreateSolutionProduct () : Promise<void>
		{
			try
			{
                const response = await CreateSolutionProductModule.createSolutionProduct();
                const id = response.Id.toString();
				this.$router.replace({ name: "solutionproductsdetails", params: { id } });
			}
			catch ( error )
			{
				console.log( error );
			}
		}

		private undo () : void
		{
			this.$router.go( -1 );
		}

		beforeDestroy (): void
		{
			CreateSolutionProductModule.RESET_STATE_CREATE();
		}
	}
