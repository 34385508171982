












	import { Component } from "vue-property-decorator";
	import { CreateSolutionProductModule, EditSolutionProductModel } from './store';

	import { mixins } from 'vue-class-component';
	import SharedMixin from "@/mixins/SharedMixin";

	import PgaSolutionProductsEdit from './Components/PgaSolutionProductsEdit.vue';
	import { EntityOrderStatus } from "@/services/iOrderService";
	import { SharedModule } from "@/feature/Shared/store";

	@Component( {
		components: {
			PgaSolutionProductsEdit
		}
	} )
    export default class SolutionProductsPageEdit extends mixins( SharedMixin )
    {

		get solutionProductEdit () : EditSolutionProductModel
		{
			return CreateSolutionProductModule.SolutionProductEdit;
		}

		private async Edit () : Promise<void>
		{
			try
			{
                await CreateSolutionProductModule.EditSolutionProduct();
				this.$router.go(-1);
			}
			// eslint-disable-next-line no-empty
			catch ( error ) { }
		}

		private undo () : void
		{
			this.$router.go( -1 );
		}

		async mounted() : Promise<void>
		{
			if(CreateSolutionProductModule.SolutionProductDetails.Status !== EntityOrderStatus.Created)
			{
				SharedModule.SET_ALERT_ERROR_MESSAGE("SolutionProduct is already approved, can't be edit anymore");
				SharedModule.SET_ALERT_IS_VISIBLE(true);
				this.$router.go(-1);
			}

			CreateSolutionProductModule.GetSolutionProductToEdit();
		}

		beforeDestroy (): void
		{
			CreateSolutionProductModule.DropSolutionProductEdit();
		}
	}
