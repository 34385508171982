













import { Component } from "vue-property-decorator"

import { mixins } from 'vue-class-component';
import SharedMixin from "@/mixins/SharedMixin";

import PgaSolutionProductsTable from './Components/PgaSolutionProductsTable.vue';

@Component({
    components: {
        PgaSolutionProductsTable
    }
})
export default class SolutionProductsIndex extends mixins(SharedMixin) { }
