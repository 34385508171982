





































import { Component, Model } from "vue-property-decorator";
import { CreateSolutionProductModule } from "../store";
import { SolutionProductModel } from "@/services/iOrderService";

import { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

@Component({})
export default class PgaSolutionProductCreate extends mixins(SharedMixin) {
  @Model("", { type: Object }) readonly Model!: SolutionProductModel;

  get code() : string {
    return this.Model.Code;
  }
  set code(code: string) {
    CreateSolutionProductModule.SET_SOLUTIONPRODUCT_CODE_CREATE(code);
  }

  get description(): string {
    return this.Model?.Description ?? "";
  }
  set description(description: string) {
    CreateSolutionProductModule.SET_SOLUTIONPRODUCT_CREATE_DESCRIPTION(description);
  }

  get typology(): string {
    return this.Model?.Typology ?? "";
  }
  set typology(typology: string) {
    CreateSolutionProductModule.SET_SOLUTIONPRODUCT_CREATE_TYPOLOGY(typology);
  }

  get typologyItems() : Array<string>
  {
    return ['Solution', 'Product'];
  }

  get Errors(): Map<string, Array<string>> {
    return CreateSolutionProductModule.SolutionProductCreate.Errors;
  }
}
